import styled from 'styled-components';
import {Link as GastbyLink} from 'gatsby';
const config = require('../data.json');

export default styled(GastbyLink)`
  color: ${config.colors.text};

  &:hover {
    opacity: 0.5;
  }
`;
