import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import {Expander, Text} from '../Widgets';
import {Link} from 'gatsby';
import {Add, ExpandLess} from '@styled-icons/material';
import SocialLinks from './SocialLinks';
import Image from './Image';
const config = require('../data.json');
const htmls = require('../content-html.json');

const items = [
  {
    label: '產品資訊',
    url: '',
    children: (
      <Fragment>
        <Link to="/products" className="nav-item">
          全部商品
        </Link>
        <Link to="/articles" className="nav-item">
          {config.mapping.articles}
        </Link>
        <Link to="/faq" className="nav-item">
          {config.mapping.faq}
        </Link>
        <Link to="/profile" className="nav-item">
          會員中心
        </Link>
      </Fragment>
    ),
  },
  {
    label: '客服資訊',
    url: '',
    children: (
      <div dangerouslySetInnerHTML={{__html: htmls.footer_service}}></div>
    ),
  },
  {
    label: '聯絡我們',
    url: '',
    children: (
      <Fragment>
        <p>客服電話：{config.information.tel}</p>
        <p>客服信箱：{config.information.email}</p>
        <p>FACEBOOK粉絲頁：{config.socialMedia.facebookName}</p>
        <p>LINE專人詢問ID：{config.socialMedia.lineName}</p>
      </Fragment>
    ),
  },
];

export default function Footer(props) {
  return (
    <Wrapper>
      <Content>
        <Row gutter={[16, 16]}>
          <Col md={4} sm={8} xs={24}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Image
                src="/images/logo-word.png"
                style={{width: '100%', height: 'auto', maxWidth: 250}}
              />

              <SocialLinks />
            </div>
          </Col>
          <Col md={11} sm={16} xs={24}>
            <div
              dangerouslySetInnerHTML={{
                __html: htmls.footer_description,
              }}></div>
          </Col>
          <Col md={2} sm={4} xs={0}>
            <p>{items[0].label}</p>
            {items[0].children}
          </Col>
          <Col md={2} sm={4} xs={0}>
            <p>{items[1].label}</p>
            {items[1].children}
          </Col>
          <Col md={5} sm={16} xs={0}>
            <p>{items[2].label}</p>
            {items[2].children}
          </Col>
          <Col md={0} sm={0} xs={24}>
            {items.map((item, idx) => (
              <Expander
                key={idx}
                title={item.label}
                ExpandMoreIcon={() => <Add size={12} color="#5a5a5a" />}
                ExpandLessIcon={() => <ExpandLess size={12} color="#5a5a5a" />}
                titleStyle={{fontSize: 16}}
                contentStyle={{padding: '10px 20px'}}>
                {item.children}
              </Expander>
            ))}
          </Col>
        </Row>
      </Content>
      <Copyright>
        <Text size="xs" color="white">
          {config.information.copyright}
        </Text>
      </Copyright>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${config.colors.third};
  min-height: 250px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  max-width: ${config.maxContentWidth}px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0 20px 0;
  flex: 1;
  color: ${config.colors.textSecond};
  font-size: 12px;

  & > p {
  }

  & .nav-item {
    margin-bottom: 12px;
    color: ${config.colors.textSecond};
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  & > .title {
    font-weight: 500;
  }

  @media screen and (max-width: ${config.maxContentWidth}px) {
    padding: 40px 20px 20px 20px;
  }

  @media screen and (max-width: ${config.breakpoints.sm}px) {
    padding: 40px 20px 20px 20px;
  }
`;

const Copyright = styled.div`
  padding: 15px;
  background-color: ${config.colors.main};
  text-align: center;
  align-self: stretch;
`;
