import {Contact} from '@styled-icons/boxicons-solid';
import React, {useState, useEffect} from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import {withPrefix} from 'gatsby';
const appConfig = require('../data.json');

let ContactUsButton = <div />;
let {enableOmnichat, facebookMessagerPageId, facebookMessagerAppId} = appConfig;

const enableFBMessenger = !!facebookMessagerPageId && !!facebookMessagerAppId;

if (enableOmnichat && !enableFBMessenger) {
  ContactUsButton = function () {
    useEffect(() => {
      try {
        const appendScript = (scriptToAppend) => {
          const script = document.createElement('script');
          script.src = scriptToAppend;
          document.body.appendChild(script);
        };

        setTimeout(() => {
          appendScript(withPrefix('Omnichat.js'));
          setTimeout(() => {
            appendScript('https://chat-plugin.easychat.co/easychat.js');
            appendScript(withPrefix('OmnichatStyle.js'));
          }, 3000);
        }, 600);
      } catch (e) {}
    }, []);
    return <div />;
  };
} else if (!enableOmnichat && enableFBMessenger) {
  ContactUsButton = function ({location}) {
    return (
      <MessengerCustomerChat
        pageId={facebookMessagerPageId}
        appId={facebookMessagerAppId}
        htmlRef={location.pathname}
        language="zh_TW"
      />
    );
  };
}

export default ContactUsButton;
