import React from 'react';
import ApiProxyFactory from './ApiProxy';
import {PRICING_TYPE} from './pricingTypes';
import {ErrNoSpec, ErrCartNotAvailable} from './errors';
import {formatValidator} from './Utils';
import Tracking from './Tracking';
const config = require('./data.json');

const Context = React.createContext();
const apiProxy = new ApiProxyFactory({apiUrl: config.endpoint.apiHost});

function transToQueryStr(params) {
  let query = '';
  if (typeof params === 'object') {
    query = Object.keys(params)
      .filter((key) => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? '?' : '&'}${key}=${params[key]}`;
        return e;
      }, '');
  }
  return query;
}

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Provider extends React.Component {
  constructor(props) {
    super(props);
    console.log('App initialization');
    this.state = {
      loading: false,
      autoLoginLoading: false,
      toastContent: null,
      modalContent: null,
      spec: null,
      productNames: [],
      promoItems: [],
      services: [], //for micro service
      categories: [],
      hashtags: [],
      blogLabels: [],
      faqLabels: [],
      profile: null,
      cart: null,
      notifs: [],
    };

    this.actions = {
      setLoading: (loading) => this.setState({loading}),
      setAutoLoginLoading: (loading) =>
        this.setState({autoLoginLoading: loading}),
      setToast: (toastContent) => this.setState({toastContent}),
      setModal: (modalContent = null) => this.setState({modalContent}),

      getJwtToken: async () => {
        return apiProxy.get({
          path: `/api/user/jwt/`,
        });
      },

      autoLogin: async () => {
        let token;
        if (typeof window !== 'undefined') {
          token = window.localStorage.getItem('token');
        }
        if (!token) {
          throw new Error('no token');
        }

        if (token) {
          apiProxy.setToken(token);
          try {
            let resp = await apiProxy.get({
              path: `/api/user/profile/`,
            });
            this.setState({profile: resp});
          } catch (ex) {
            if (!(ex instanceof TypeError)) {
              apiProxy.setToken(null);
              window.localStorage.removeItem('token');
            }
            throw ex;
          }
        }
      },

      login: async ({username, password}) => {
        try {
          let data = {password};
          if (formatValidator.isEmail(username)) {
            data.email = username;
          } else {
            data.username = username;
          }

          let resp = await apiProxy.post({
            path: `/api/user/login/`,
            data,
          });

          apiProxy.setToken(resp.token);
          window.localStorage.setItem('token', resp.token);
          this.setState({profile: resp});
        } catch (ex) {
          apiProxy.setToken(null);
          window.localStorage.removeItem('token');
          throw ex;
        }
      },

      logout: async () => {
        apiProxy.setToken(null);
        window.localStorage.removeItem('token');
        this.setState({
          profile: null,
          cart: null,
          notifs: [],
        });
      },

      getProfile: async () => {
        let resp = await apiProxy.get({
          path: `/api/user/profile/`,
        });

        this.setState({profile: resp});
        return resp;
      },

      editProfile: async (data) => {
        let formData = new FormData();
        delete data.user;
        for (let key in data) {
          formData.append(key, data[key]);
        }

        return apiProxy.formPut({
          path: '/api/user/profile/',
          formData,
        });
      },

      changePassword: async (data) => {
        return apiProxy.post({
          path: `/api/user/change_password/`,
          data,
        });
      },

      forgetPassword: async (data) => {
        return apiProxy.post({
          path: `/api/user/password/forgot/`,
          data,
        });
      },

      //驗證信箱
      validEmail: ({email}) => {
        //此api 也會 確認信箱有無已註冊

        return apiProxy.post({
          path: `/api/user/profile/validation/request/`,
          data: {
            identity: email,
          },
        });
      },

      register: async ({access_token, password}) => {
        return apiProxy.post({
          path: `/api/user/register/`,
          data: {
            access_token,
            password,
          },
        });
      },

      setSpec: async (spec) => {
        try {
          this.setState({spec});

          let productNames = [];
          for (let t of PRICING_TYPE) {
            if (t === 'custom') {
              continue;
            }
            productNames = productNames.concat(
              spec[t].map((product) => product.name),
            );
          }
          this.setState({productNames});
        } catch (err) {
          console.warn('get spec fail', err);
        }
      },

      getSpec: async (version) => {
        if (this.state.spec && version === this.state.spec.version) {
          return this.state.spec;
        } else if (version) {
          return await (
            await fetch(`${config.endpoint.specUrl}/${version}.json`)
          ).json();
        } else {
          return await (
            await fetch(`${config.endpoint.specUrl}/latest.json`)
          ).json();
        }
      },

      getProductFromSpec: ({productName, spec = null}) => {
        // maybe previous version
        let _spec = spec || this.state.spec;

        if (!_spec) {
          return new ErrNoSpec();
        }

        for (let t of PRICING_TYPE) {
          const product = _spec[t].find((p) => p.name === productName);
          if (product) {
            return product;
          }
        }

        return null;
      },
      getProducts: async () => {
        const resp = await apiProxy.get({
          path: `/api/product`,
          secure: false,
        });
        return resp.results;
      },

      getProduct: async (id) => {
        return apiProxy.get({
          path: `/api/product/${id}`,
          secure: false,
        });
      },

      getProductByName: async (name) => {
        let resp = await apiProxy.get({
          path: `/api/product?name=${encodeURIComponent(name)}`,
          secure: false,
        });

        return resp.results.length ? resp.results[0] : null;
      },

      calcPrice: async (data) => {
        return apiProxy.post({
          withHost: true,
          path: `${config.endpoint.calcPriceUrl}/product/calc-price?client_id=${config.client}`,
          data,
        });
      },

      getPromoItems: async () => {
        // type = banner || top_zone || bottom_zone
        try {
          let promoItems = await apiProxy.get({
            path: `/api/promo_item/`,
          });

          this.setState({promoItems});
        } catch (err) {}
      },

      getServices: async () => {
        try {
          let resp = await apiProxy.get({
            path: `${config.endpoint.serviceUrl}/v1/service?client_id=${config.client}`,
            withHost: true,
          });
          this.setState({services: resp});
        } catch (err) {}
      },

      getCategories: async () => {
        try {
          let service = this.state.services.find(
            (sv) => sv.service_name === 'jstorage',
          );
          let resp = await apiProxy.post({
            path: `${service.domain}/${service.latest}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'products'}},
          });

          this.setState({categories: resp.children});
        } catch (err) {
          console.warn('Fail: Get Categories');
        }
      },

      getHashtags: async () => {
        try {
          let service = this.state.services.find(
            (sv) => sv.service_name === 'jstorage',
          );
          let resp = await apiProxy.post({
            path: `${service.domain}/${service.latest}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'hashtags'}},
          });

          this.setState({hashtags: resp.children});
        } catch (err) {
          console.warn('Fail: Get Hashtags');
        }
      },

      //#region CART
      getCart: async () => {
        try {
          let resp = await apiProxy.get({
            path: '/api/cart/',
          });
          this.setState({cart: resp.cart});
        } catch (ex) {
          console.warn('DBG', ex);
        }
      },

      addItem: async (item) => {
        if (!this.state.cart) {
          throw new ErrCartNotAvailable();
        }

        let resp = await apiProxy.post({
          path: '/api/cart2/add_item/',
          data: {
            ...item,
            version: this.state.spec.version,
          },
        });
        this.setState({cart: resp.cart});

        Tracking.addToCart(item);
      },

      updateItem: async (index, item) => {
        let resp = await apiProxy.post({
          path: '/api/cart2/edit_item/',
          data: {index, config: item.config, version: this.state.spec.version},
        });
        this.setState({cart: resp.cart});
      },

      removeItem: async (index) => {
        let resp = await apiProxy.post({
          path: '/api/cart2/delete_item/',
          data: {index},
        });

        Tracking.removeFromCart(this.state.cart, index);

        this.setState({cart: resp.cart});
      },

      editConfig: async (config) => {
        let resp = await apiProxy.post({
          path: '/api/cart/edit_config/',
          data: {
            config: JSON.stringify(config),
          },
        });
        this.setState({cart: resp.cart});
      },

      checkout: async (data) => {
        return apiProxy.post({
          path: '/checkout/order/',
          data: {...data},
        });
      },
      //#endregion CART

      //#region ORDER
      getOrders: async (params = {}) => {
        params.ordering = '-created';
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/checkout/order/${query}`,
        });
      },

      getOrder: async (id) => {
        return apiProxy.get({
          path: `/checkout/order/${id}/`,
        });
      },

      editOrderBuyer: async ({id, buyer}) => {
        return apiProxy.put({
          path: `/api/order/${id}/`,
          data: {
            buyer_id: buyer,
          },
        });
      },

      editOrderDownloaded: async ({id, ...data}) => {
        return apiProxy.put({
          path: `/api/order/downloaded/${id}`,
          data,
        });
      },

      getOrderItems: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/order_item/${query}`,
        });
      },

      editOrderItem: async ({id, ...data}) => {
        return apiProxy.put({
          path: `/api/order_item/${id}/`,
          data,
        });
      },

      getLogistics: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/logistics/${query}`,
        });
      },

      addAttatchment: async (data) => {
        return apiProxy.post({
          path: `/api/order_item/attachment/`,
          data,
        });
      },

      getInvoices: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/issue/invoice/${query}`,
        });
      },

      getRefunds: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/refund/${query}`,
        });
      },

      editRefund: async (data) => {
        return apiProxy.put({
          path: `/api/refund/${data.id}`,
          data,
        });
      },

      getReturnApps: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/return_app/${query}`,
        });
      },

      createReturnApp: async (params) => {
        let formData = new FormData();
        for (let key in params) {
          if (params[key] !== undefined && params[key] !== null) {
            formData.append(key, params[key]);
          }
        }
        return apiProxy.formPost({
          path: `/api/return_app/`,
          formData,
        });
      },

      voidOrder: async ({id, void_reason}) => {
        return apiProxy.post({
          path: `/checkout/order/${id}/void/`,
          data: {
            void_reason,
          },
        });
      },

      createCreditsOrder: async (credits) => {
        return apiProxy.post({
          path: '/api/buy_credits/',
          data: {
            credits,
          },
        });
      },

      getPromotionFeedback: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/promotion/feedback/${query}`,
        });
      },

      getCustomCalculations: async (params) => {
        return apiProxy.post({
          path: `/api/order/custom/calc/`,
          data: params,
        });
      },

      createCustomOrder: async (params) => {
        return apiProxy.post({
          path: `/api/order/custom/`,
          data: params,
        });
      },

      //#endregion ORDER

      //#region  BLOG
      getBlogLabels: async () => {
        try {
          let service = this.state.services.find(
            (sv) => sv.service_name === 'jstorage',
          );
          let resp = await apiProxy.post({
            path: `${service.domain}/${service.latest}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'articles'}},
          });

          this.setState({blogLabels: resp.children});
        } catch (err) {
          console.warn('Fail: Get Blog Labels');
        }
      },

      getFaqLabels: async () => {
        try {
          let service = this.state.services.find(
            (sv) => sv.service_name === 'jstorage',
          );
          let resp = await apiProxy.post({
            path: `${service.domain}/${service.latest}/document/categories/find-one?client_id=${config.client}`,
            withHost: true,
            data: {query: {name: 'faqs'}},
          });

          this.setState({faqLabels: resp.children});
        } catch (err) {
          console.warn('Fail: Get Faq Labels');
        }
      },

      getBlogs: async (data) => {
        let service = this.state.services.find(
          (sv) => sv.service_name === 'jstorage',
        );

        return apiProxy.post({
          path: `${service.domain}/${service.latest}/document/Article_Default/find?client_id=${config.client}`,
          withHost: true,
          data,
        });
      },

      getBlog: async (data) => {
        let service = this.state.services.find(
          (sv) => sv.service_name === 'jstorage',
        );

        return apiProxy.post({
          path: `${service.domain}/${service.latest}/document/Article_Default/find-one?client_id=${config.client}`,
          withHost: true,
          data,
        });
      },

      //#endregion BLOG

      addNotif: (notif) =>
        this.setState((state) => ({notifs: [...state.notifs, notif]})),

      clearNotifs: () => this.setState({notifs: []}),

      getUsers: async (params) => {
        let query = transToQueryStr(params);
        return apiProxy.get({
          path: `/api/user/profile/all/${query}`,
        });
      },

      contact: async (data) => {
        if (!data.data) {
          delete data.data;
        }
        return apiProxy.post({
          path: `/api/contact/`,
          data,
        });
      },
    };
  }

  async componentDidMount() {
    this.actions.getPromoItems();
    this._initSpec();

    await this.actions.getServices();
    this.actions.getCategories();
    this.actions.getHashtags();
    this.actions.getBlogLabels();
    this.actions.getFaqLabels();
  }

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          actions: this.actions,
        }}>
        {this.props.children}
      </Context.Provider>
    );
  }

  _initSpec = async () => {
    let spec = await this.actions.getSpec();
    this.actions.setSpec(spec);
  };
}

export {Context, Provider};
