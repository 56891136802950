import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import Mask from './Mask';
const config = require('../data.json');

export default function DropdownSelector(props) {
  const [open, setOpen] = useState(false);

  const {
    css = '',
    optionsArr,
    selectedOption,
    onChoiceClick,
    displayMap,
    /* 
    pass displayMap if need translate 
    format-->
     {
      single: "單面",
      double: "雙面"
     }
    */

    /* for multiple selector */
    multiple = false,
    multiSelectedArr,
  } = props;

  return (
    <Wrapper css={css} open={open}>
      <div className="row">
        <div
          className="button-wrapper"
          onClick={() => {
            setOpen((value) => !value);
          }}>
          <p>
            {(() => {
              if (multiple) {
                return `選擇多選選項`;
              } else {
                return displayMap
                  ? displayMap[`${selectedOption}`]
                  : selectedOption;
              }
            })()}
          </p>
          <div className="arrow-down-wrapper">
            <div className="arrow-down" />
          </div>
        </div>
        <Mask
          visible={open}
          onClick={(e) => {
            setOpen(false);
          }}
        />
        <div className="toggle-menu">
          {optionsArr.map((o, idx) => (
            <div
              className={'menu-item' + (o === selectedOption ? ' active' : '')}
              key={idx}
              onClick={(e) => {
                onChoiceClick(o, idx);
                setOpen(false);
              }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {(() => {
                  if (multiple && Array.isArray(multiSelectedArr)) {
                    return multiSelectedArr.indexOf(o) >= 0 ? (
                      <p className="dot" />
                    ) : (
                      <p className="empty-dot" />
                    );
                  }
                })()}
                <p
                  style={{
                    overflowWrap: 'break-word',
                    flex: 1,
                  }}>
                  {displayMap ? displayMap[`${o}`] : o}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {multiple &&
        multiSelectedArr &&
        multiSelectedArr.map((i) => (
          <div className="multiple-selected-item">
            <p>{i}</p>
          </div>
        ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & .row {
    position: relative;

    & .button-wrapper {
      cursor: pointer;
      padding: 0 0 0 10px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      height: 35px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      & > .arrow-down-wrapper {
        display: flex;
        border-left: 1px solid #cccccc;
        width: 35px;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        & > .arrow-down {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 5px 0 5px;
          border-color: #717071 transparent transparent transparent;
        }
      }

      & > p {
        color: ${config.colors.main};
        font-size: 14px;
        line-height: 17px;
        padding-top: 13px;
      }
    }

    & .toggle-menu {
      z-index: 1;
      position: absolute;
      background-color: white;
      left: 0px;
      top: ${(props) => (props.open ? '100%' : '60%')};
      padding: 20px 0px;
      width: auto;
      min-width: 100%;
      width: min(65vw, 250px);
      max-height: 500px;
      overflow: auto;
      border: 1px solid #cccccc;
      box-shadow: -2px -2px 7px 1px rgba(132, 132, 132, 0.06);
      /* animation */
      transition: all 0.2s;
      pointer-events: ${(props) => (props.open ? 'all' : 'none')};
      opacity: ${(props) => (props.open ? 1 : 0)};
      /* white-space: nowrap; */

      & .menu-item {
        font-size: 14px;
        /* line-height: 20px; */
        letter-spacing: 0.4px;
        color: #4e4e4e;
        background-color: white;
        text-transform: uppercase;
        cursor: pointer;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        background-color: #fff;
        transition: background-color 0.3s;

        &:hover {
          background-color: rgba(200, 200, 200, 0.5);
          color: ${config.colors.main};
        }

        & .empty-dot {
          margin-right: 5px;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          background-color: #fff;
          border: 1px solid #888888;
          flex-shrink: 0;
        }

        & .dot {
          margin-right: 5px;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          background-color: ${config.colors.main};
          border: 1px solid ${config.colors.main};
          box-shadow: inset 0 0 0 1px white;
          flex-shrink: 0;
        }
      }

      & .menu-item.active {
        color: black;
      }
    }
  }

  & .multiple-selected-item {
    display: flex;
    background-color: ${config.colors.main};
    padding: 5px 15px;
    margin-top: 7px;
    border-radius: 2px;

    & > p {
      color: white;
      font-size: 14px;
      margin: 0;
    }
  }
  ${(props) => props.css}
`;
