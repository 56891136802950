import React, {useState} from 'react';
import styled from 'styled-components';
import {ExpandLess, ExpandMore} from 'styled-icons/material';
const config = require('../data.json');

export default function MenuItem(props) {
  const [open, setOpen] = useState(false);
  const {onClick, node} = props;

  if (!node) {
    return null;
  }
  const isRoot = node.name === 'root';
  const hasChildren = node.children && node.children.length > 0;

  return (
    <div>
      {!isRoot && (
        <Item
          onClick={() => {
            if (!hasChildren) {
              return;
            }

            setOpen((value) => !value);
          }}>
          <div
            style={{flex: 1}}
            onClick={(e) => {
              e.stopPropagation();

              onClick(node);
            }}>
            {node.display}
          </div>
          {hasChildren && open && (
            <ExpandLess size={18} color={config.colors.text} />
          )}
          {hasChildren && !open && (
            <ExpandMore size={18} color={config.colors.text} />
          )}
        </Item>
      )}

      {(open || isRoot) && hasChildren && (
        <div style={{paddingLeft: isRoot ? 0 : 15}}>
          {node.children.map((child) => {
            return <MenuItem node={child} key={child.name} onClick={onClick} />;
          })}
        </div>
      )}
    </div>
  );
}

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: ${config.colors.main};
  }
`;

export const HashTag = styled.div`
  padding: 3px 8px;
  font-size: 12px;
  border: 1px solid #ddd;
  margin-right: 5px;
  margin-bottom: 5px;
  color: ${config.colors.text};
  cursor: pointer;
  ${(props) =>
    props.selected &&
    `
    background-color: ${config.colors.second};
    color: white;
  `}

  &:hover {
    background-color: ${config.colors.second};
    color: white;
  }
`;
