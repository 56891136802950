import React from 'react';
import Rotate from './Rotate';
import Line from './Line';
import {Context} from '../../AppContext';
const config = require('../../data.json');

export default function ActivityIndicator() {
  const app = React.useContext(Context);
  const comp = config.spinner || 'rotate';

  let params = {
    visible: app.state.loading,
    color: config.colors.main,
  };

  switch (comp) {
    case 'line':
      return <Line {...params} />;
    case 'rotate':
    default:
      return <Rotate {...params} />;
  }
}
