import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import 'antd/dist/antd.less';
import '../../index.css';
import Toast from '../Toast';
import Footer from '../Footer';
import Modal from '../../Modals';
import Navbar from '../Navbar';
import Delighters from '../ReactDelighters';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import ContactUsButton from '../ContactUsButton';
import {withPrefix} from 'gatsby';
const appConfig = require('../../data.json');

function normalizePath(path) {
  if (path.slice(0, 2) === '//') {
    path = path.slice(1);
  }

  if (path[path.length - 1] !== '/') {
    path += '/';
  }

  return path;
}

const Layout = ({location, children, ...props}) => {
  const _pathname = normalizePath(location.pathname);

  return (
    <main>
      <Helmet>
        {/* <link rel="shortcut icon" href="/favicon.ico" /> */}
        <title>{appConfig.information.name}</title>

        <script src={'/yahoo-ad-tracking.js'} type="application/javascript" />
      </Helmet>
      <Delighters>
        <Content>
          <Navbar pathname={_pathname} />
          <div
            style={{
              flex: 1,
              position: 'relative',
              minHeight: '70vh',
              display: 'flex',
              flexDirection: 'column',
            }}>
            {children}
          </div>
          <Footer />
        </Content>
      </Delighters>
      <Toast />
      <Modal />

      <ContactUsButton />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Content = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export default Layout;
