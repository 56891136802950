import GA from './GA';
import Pixel from './FbPixel';
const config = require('../data.json');

const DEBUG = false;

const Catch = (fn) =>
  function (...args) {
    try {
      fn.apply(this, args);
      if (config.debug && DEBUG) {
        console.log('tracking success', fn.name, args);
      }
    } catch (e) {
      if (config.debug && DEBUG) {
        console.error('tracking error:', e);
      }
    }
    return;
  };

const Tracking = {
  addToCart: Catch((item) => {
    GA.addToCart([{id: item.name, quantity: item.quantity}], item.quantity);

    Pixel.addToCart(
      {
        ...item,
        id: item.name,
      },
      item.quantity,
    );
  }),
  removeFromCart: Catch((cart, index) => {
    let item = cart.items[index];

    GA.removeFromCart(
      {
        ...item,
        id: item.name,
      },
      item.config.quantity_select.quantity,
    );
  }),
  purchase: Catch((order) => {
    const cart = JSON.parse(order.cart);
    GA.purchase(
      cart.items.map((_i) => ({..._i, id: _i.name})),
      {
        ...order,
        tax: cart.calculations.tax || 0,
        shipping: cart.calculations.fee || 0,
      },
    );

    Pixel.purchase(order.amount, cart.items);
  }),
  addImpression: Catch((product) => {
    GA.addImpression(
      {
        id: product.name,
        name: product.name,
      },
      undefined,
      'ProductDetail',
    );

    Pixel.viewContent([product.name]);
  }),
  searchResultClick: Catch(() => {
    // TODO: not sure bind to where ?
  }),
  checkout: Catch((step, cart, params) => {
    const items = cart ? cart.items : [];

    if (items.length > 0) {
      let payment = `${params.payment_type}${
        params.payment_subtype ? '_' + params.payment_subtype : ''
      }`;

      GA.checkout(
        items.map((_i) => ({..._i, id: _i.name})),
        step + 1,
        payment,
      );

      if (step === 0) {
        Pixel.initCheckout();
      }
    }
  }),
  addPromotionImpression: Catch((promo) => {
    GA.addPromotionImpression(promo);
  }),
  addPromotionImpressionClick: Catch((promo) => {
    GA.addPromotionImpressionClick(promo);
  }),
  // customEvent: Catch(function _customEvent(category, action, label, value) {
  //   if (label != null && value != null) {
  //     window.ga("send", "event", category, action, label, value);
  //     return;
  //   }

  //   if (label != null) {
  //     window.ga("send", "event", category, action, label);
  //     return;
  //   }

  //   window.ga("send", "event", category, action);
  // }),
};

export default Tracking;
