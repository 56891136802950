import React from 'react';
import {Space} from 'antd';
import Image from './Image';
const config = require('../data.json');

const links = [
  {
    image: 'social-facebook.png',
    link: config.socialMedia.facebookLink,
  },
  {
    image: 'social-line.png',
    link: config.socialMedia.lineLink,
  },
  {
    image: 'social-shopee.png',
    link: config.socialMedia.shopeeLink,
  },
];

export default function Links(props) {
  return (
    <Space>
      {links.map((link, idx) => (
        <Image
          key={idx}
          linkto={link.link}
          src={`/images/${link.image}`}
          style={{height: 30, width: 30}}
        />
      ))}
    </Space>
  );
}
