import React from 'react';
import styled from 'styled-components';
const config = require('../data.json');

const sizes = {
  lg: 20,
  md: 10,
  sm: 5,
};
const positions = {
  left: 'left',
  center: 'center',
  right: 'right',
};

export default function Divider(props) {
  const {paddingSize = 'md', position = positions.center} = props;

  return props.children ? (
    <Wrapper>
      {position !== positions.left && <Line paddingSize={paddingSize} />}
      <div style={{margin: '0 10px', whiteSpace: 'nowrap'}}>
        {props.children}
      </div>
      {position !== positions.right && <Line paddingSize={paddingSize} />}
    </Wrapper>
  ) : (
    <Line paddingSize={paddingSize} />
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${config.colors.border};
  margin: ${(props) => sizes[props.paddingSize]}px 0;
`;
