const Pixel = {
  addToCart: (contents, value) => {
    window.fbq('track', 'AddToCart', {
      contents,
      content_type: 'product',
      value,
      currency: 'TWD',
    });
  },
  viewContent: (content_ids) => {
    window.fbq('track', 'ViewContent', {
      content_ids,
      content_type: 'product',
    });
  },
  initCheckout: () => {
    window.fbq('track', 'InitiateCheckout');
  },
  purchase: (value, _contents) => {
    let contents = _contents.map((content) => {
      try {
        let q1 = parseInt(content.config.quantity_select),
          q2 = parseInt(content.quantity);

        return {
          id: content.name,
          quantity: q1 > q2 ? q1 : q2,
        };
      } catch (e) {
        return {
          id: content.name,
          quantity: 1,
        };
      }
    });
    window.fbq('track', 'Purchase', {
      content_type: 'product',
      value,
      contents,
      currency: 'TWD',
    });
  },
  search: (search_string) => {
    window.fbq('track', 'Search', {
      search_string,
    });
  },
};

export default Pixel;
