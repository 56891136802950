import React from 'react';
import styled from 'styled-components';

export default function Mask({visible = false, onClick, color = '#fff9'}) {
  return visible ? <MaskComp color={color} onClick={onClick} /> : null;
}

const MaskComp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.color};
  z-index: 1;
`;
