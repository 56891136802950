import React, {useState, Fragment, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Expander, Text, Divider} from '../../../Widgets';
import {Add, ExpandLess} from '@styled-icons/material';
import {Context} from '../../../AppContext';
import MenuItem from '../../MenuItem';
import SocialLinks from '../../SocialLinks';
const config = require('../../../data.json');

const items = [
  {label: '全部商品', url: ''},
  {label: config.mapping['about'], url: '/about'},
  {label: config.mapping['articles'], url: '/articles'},
  {label: config.mapping['faq'], url: '/faq'},
];

export default function Menu({onClose}) {
  const app = useContext(Context);
  const promozones = app.state.promoItems.filter(
    (x) => x.type === 'bottom_zone',
  );

  return (
    <Wrapper>
      {items.map((item, idx) => {
        if (item.label === '全部商品') {
          return (
            <Expander
              key={idx}
              title={item.label}
              expand={false}
              ExpandMoreIcon={() => <Add size={12} color="#5a5a5a" />}
              ExpandLessIcon={() => <ExpandLess size={12} color="#5a5a5a" />}
              titleStyle={{fontSize: 16}}
              contentStyle={{padding: '10px 20px'}}>
              <Text
                size="xs"
                color={config.colors.main}
                style={{marginBottom: 10}}>
                全部商品
              </Text>
              {app.state.categories &&
                app.state.categories.map((c, idx) => (
                  <MenuItem
                    key={idx}
                    node={c}
                    onClick={(c) => {
                      navigate(`/products${c.name ? '?name=' + c.name : ''}`);
                      onClose();
                    }}
                  />
                ))}

              <Divider />

              <Text
                size="xs"
                color={config.colors.main}
                style={{marginBottom: 10}}>
                促銷專區
              </Text>
              {promozones.length === 0
                ? null
                : // <LoadingBlock height="200"/>
                  promozones.map((z, idx) => (
                    <MenuItem
                      key={idx}
                      node={{display: z.label, name: z.name}}
                      onClick={() => {
                        navigate(`/products?zone=${z.id}`);
                        onClose();
                      }}
                    />
                  ))}
            </Expander>
          );
        }
        return (
          <Expander
            key={idx}
            title={item.label}
            titleStyle={{fontSize: 16}}
            url={item.url}
          />
        );
      })}

      <SocialLinks />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
