import React from 'react';
import styled from 'styled-components';
const config = require('../data.json');

export default function Badge(props) {
  let {count = 0, showZero = false} = props;
  const show = count > 0 || (count === 0 && showZero);

  if (props.children) {
    return (
      <Style.Wrapper>
        {props.children}
        {show && (
          <Style.Circle>
            <Style.Text>{count}</Style.Text>
          </Style.Circle>
        )}
      </Style.Wrapper>
    );
  }

  return (
    show && (
      <Style.Circle>
        <Style.Text>{count}</Style.Text>
      </Style.Circle>
    )
  );
}

const Style = {
  Wrapper: styled.div`
    position: relative;
  `,
  Circle: styled.div`
    position: absolute;
    right: -6px;
    top: 1px;
    background-color: ${config.colors.main};
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 2px white;
  `,
  Text: styled.p`
    margin: 0;
    color: #fff;
    font-size: 9px;
  `,
};
