function isPersonCertificatedCode(string) {
  //台灣自然人憑證(2碼英文字母加上14碼數字)
  var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;
  return regexp.test(string);
}

function isMobileVehicleCode(string) {
  // 手機載具(斜線(/)加上7碼數字或大寫字母)
  var regexp = /^\/[0-9A-Z+-]{7}$/;
  return regexp.test(string);
}

function isCompanyId(string) {
  //公司統一編號(由8位數字組成)
  var regexp = /^[0-9]{8}$/;
  return regexp.test(string);
}

function isLoveCode(string) {
  //愛心捐贈碼(由3至7位數字組成)
  var regexp = /^[0-9]{3,7}$/;
  return regexp.test(string);
}

function isNotEmpty(value) {
  if (typeof value === 'string') {
    return value.trim();
  }

  return value === 0 || value;
}

function isEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function isMobileNumber(phone) {
  let re = /^([0|+[0-9]{1,5})?([7-9][0-9]{8})$/;
  return re.test(phone);
}

function isNumeric(value) {
  let re = /^[0-9]*$/;
  return re.test(value);
}

const formatValidator = {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
  isNotEmpty,
  isEmail,
  isMobileNumber,
  isNumeric,
};

export default formatValidator;
