import React, {useContext, useEffect} from 'react';
import * as AppContext from './AppContext';
import Layout from './Components/Layout';
import ActivityIndicator from './Components/ActivityIndicator';

export function PageContainer(props) {
  return (
    <WithPageContext {...props}>
      <Layout {...props}>{props.children}</Layout>
    </WithPageContext>
  );
}

function WithPageContext({pageContext, children}) {
  const app = useContext(AppContext.Context);
  // const {prjConfig} = pageContext;

  useEffect(() => {
    (async () => {
      if (!app.state.user) {
        try {
          app.actions.setAutoLoginLoading(true);
          await app.actions.autoLogin();
          app.actions.getCart();
        } catch (err) {
          console.warn('autologin error', err);
        }
        app.actions.setAutoLoginLoading(false);
      }
    })();
  }, [app.actions, app.state.user]);

  return children;
}

export function AppRoot(props) {
  return (
    <AppContext.Provider {...props}>
      {props.children}
      <ActivityIndicator />
    </AppContext.Provider>
  );
}
