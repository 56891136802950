const GA = {
  addToCart: function _addToCart(product, qty) {
    window.ga('ec:addProduct', {
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: qty,
    });
    window.ga('ec:setAction', 'add');
    window.ga('send', 'event', 'Click', 'Cart', 'Add ' + product.id);
  },
  removeFromCart: function _removeFromCart(product, qty) {
    window.ga('ec:addProduct', {
      id: product.id + '',
      name: product.name,
      //category: product.label,
      //brand: product.store.name,
      price: product.price,
      quantity: qty,
    });
    window.ga('ec:setAction', 'remove');
    window.ga('send', 'event', 'Click', 'Cart', 'Remove ' + product.id);
  },
  purchase: function _purchase(items = [], order = {}) {
    if (items.length > 0) {
      // items.map(item => {
      //   let q1 = parseInt(item.config.quantity_select),
      //     q2 = parseInt(item.quantity);
      //   let q = q1 > q2 ? q1 : q2;

      //   window.ga("ec:addProduct", {
      //     id: item.id + "",
      //     name: item.name,
      //     price: parseInt(item.price / q),
      //     quantity: q,
      //   });
      // });
      window.ga('ec:setAction', 'purchase', {
        id: order.id + '',
        affiliation: 'Lixiang',
        revenue: order.amount + '',
        tax: order.tax,
        shipping: order.shipping,
      });
    }
    window.ga('send', 'event', 'Purchase', items.length + ' items');
    window.ga(
      'send',
      'event',
      'Purchase',
      order.payment_type + ' ' + order.payment_subtype,
    );
  },
  addImpression: function _addImpression(
    item,
    position = undefined,
    from = '',
  ) {
    window.ga('ec:addImpression', {
      id: item.id, // Product ID (string).
      name: item.name, // Product name (string).
      //category: item.label, // Product category (string).
      //brand: item.store.name, // Product brand (string).
      //list: "Search Results", // Product list (string).
      ...(position !== undefined ? {position} : {}), // Product position (number).
    });
    window.ga('send', 'event', from + '-Impression', 'Product', item.name);
    window.ga('send', 'pageview');
  },
  searchResultClick: function _searchResultClick() {
    // TODO: not sure bind to where ?
  },
  checkout: function _checkout(items = [], step, payment = '-') {
    if (items.length > 0) {
      // items.map(item => {
      //   let q1 = parseInt(item.config.quantity_select),
      //     q2 = parseInt(item.quantity);
      //   let q = q1 > q2 ? q1 : q2;
      //   window.ga("ec:addProduct", {
      //     id: item.id + "",
      //     name: item.name,
      //     price: parseInt(item.price / q),
      //     quantity: q,
      //   });
      // });
      window.ga('ec:setAction', 'checkout', {
        step,
        option: payment,
      });
      if (payment !== '-' || payment !== '') {
        window.ga('send', 'event', 'Checkout', 'Payment', payment);
      }
      window.ga('send', 'event', 'Checkout', 'Step', step + '');
    }
  },
  addPromotionImpression: function _addPromotionImpression(item, position) {
    window.ga('ec:addPromo', {
      // Promo details provided in a promoFieldObject.
      id: item.id + '', // Promotion ID. Required (string).
      name: item.name, // Promotion name (string).
      //'creative': 'summer_banner2',   // Creative (string).
      position: item.priority, // Position  (string).
    });
    window.ga(
      'send',
      'event',
      'Promotion-Impression',
      position || '---',
      item.name,
    );
  },
  addPromotionImpressionClick: function _addPromotionImpressionClick(
    item,
    position,
  ) {
    window.ga('ec:addPromo', {
      // Promo details provided in a promoFieldObject.
      id: item.id + '', // Promotion ID. Required (string).
      name: item.name, // Promotion name (string).
      //'creative': 'summer_banner2',   // Creative (string).
      position: item.priority, // Position  (string).
    });
    window.ga('ec:setAction', 'promo_click');
    window.ga(
      'send',
      'event',
      'Promotion-Impression-Click',
      position || '---',
      item.name,
    );
  },
  customEvent: function _customEvent(category, action, label, value) {
    if (label != null && value != null) {
      window.ga('send', 'event', category, action, label, value);
      return;
    }

    if (label != null) {
      window.ga('send', 'event', category, action, label);
      return;
    }

    window.ga('send', 'event', category, action);
  },
};

export default GA;
