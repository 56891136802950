class ErrNoSpec extends Error {}
class ErrCartNotAvailable extends Error {}
class ErrParamTypeNotAvailable extends Error {}
class ErrInvoiceFormat extends Error {}
class ErrCheckoutForm extends Error {}
class ErrUploadFile extends Error {}
class ErrProfileFormat extends Error {}
class ErrCustomOrder extends Error {}
class ErrRegister extends Error {}
class ErrForgetPwd extends Error {}

export {
  ErrNoSpec,
  ErrCartNotAvailable,
  ErrParamTypeNotAvailable,
  ErrInvoiceFormat,
  ErrCheckoutForm,
  ErrUploadFile,
  ErrProfileFormat,
  ErrCustomOrder,
  ErrRegister,
  ErrForgetPwd,
};
