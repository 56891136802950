import React, {useState, Fragment, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Text} from '../../../Widgets';
import {Context} from '../../../AppContext';
import MenuItem from '../../MenuItem';
const config = require('../../../data.json');

export default function Category({onClose}) {
  const app = useContext(Context);
  const promozones = app.state.promoItems.filter(
    (x) => x.type === 'bottom_zone',
  );
  const count = 6;

  return (
    <Wrapper>
      <div>
        <Text size="sm" color={config.colors.main} style={{marginBottom: 10}}>
          全部商品
        </Text>
        <div style={{marginRight: 10, display: 'flex'}}>
          {/* /// 3 column, 6 row */}
          {[...Array(3).keys()].map((column) => (
            <div style={{marginRight: 10}} key={column}>
              {app.state.categories &&
                app.state.categories
                  .slice(column * count, (column + 1) * count)
                  .map((c, idx) => (
                    <MenuItem
                      key={idx}
                      node={c}
                      onClick={(c) => {
                        onClose();
                        navigate(`/products${c.name ? '?name=' + c.name : ''}`);
                      }}
                    />
                  ))}
            </div>
          ))}
        </div>
      </div>
      <div>
        <Text size="sm" color={config.colors.main} style={{marginBottom: 10}}>
          促銷專區
        </Text>
        <div style={{marginRight: 10, display: 'flex'}}>
          {/* /// 2 column, 6 row */}
          {[...Array(2).keys()].map((column) => (
            <div style={{marginRight: 10}} key={column}>
              {promozones.length === 0
                ? null
                : // <LoadingBlock height="200"/>
                  promozones
                    .slice(column * count, (column + 1) * count)
                    .map((z, idx) => (
                      <MenuItem
                        key={idx}
                        node={{display: z.label, name: z.name}}
                        onClick={() => {
                          onClose();
                          navigate(`/products?zone=${z.id}`);
                        }}
                      />
                    ))}
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  white-space: nowrap;
`;
